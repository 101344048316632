import { render, staticRenderFns } from "./Join.vue?vue&type=template&id=0bb2ed3e&scoped=true&"
import script from "./Join.vue?vue&type=script&lang=js&"
export * from "./Join.vue?vue&type=script&lang=js&"
import style0 from "./Join.vue?vue&type=style&index=0&id=0bb2ed3e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bb2ed3e",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QSlideTransition from 'quasar/src/components/slide-transition/QSlideTransition.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QBtn,QChip,QCardActions,QSpace,QSlideTransition,QSeparator});
